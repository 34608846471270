import ApiRequest    from '../../api/request.js';
import FormLoader    from '../Form/FormLoader';
import {schemaFieldToFormInput} from '../Form/utility';
import Table    from '../Table/Table';
import React 		 from 'react';
import MapButton from '../Table/MapButton';
import { GoogleMap } from '../googleMap';

/**
  State:
    @param 	{bool} 		a		Definition

  Props:
    @param  {String}  model   Name of model we are in context of
*/
export default class DataManager extends React.Component
{
	_isMounted = false;
  _refreshData = false;
	_table = null;

	// MARK: - Constructor
	constructor(props)
	{
		console.log('\tDataManager()');
		super(props);
		this.state =
		{
			isLoading: false,
			tableData: null,

      modelDoc: null,

			updateFormInputs: [],
      createFormInputs: [],

      mapVisible: false,
      geofenceAreas: [],
      usersLocation: [],
		}

		this._table = React.createRef();
	}

	componentDidMount()
	{
		console.log('\tDataManager.componentDidMount()');

    if(!this._isMounted)
    {
	     this._isMounted = true;
	     this.loadData();
    }
	}


  // MARK: - API
  /**
  	Load model
	*/
	loadData = async () =>
	{
		console.log('\tDataManager.loadData()');
		this.setState({ isLoading: true });
    console.log(this.props.model);
		var params =
    {
      model: this.props.model,
      params:
      {

      }
    };

		//console.log(params);
		try
		{
			// Get data
      // TODO: Setup to use ApiManager instead
			var response = await ApiRequest.sendRequest("post", params, "data/query", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
        return;
			}

      //console.log('DataManager.loadData response:\n' + JSON.stringify(response.data, null, 2));

      var updateFormInputs = [];
      var createFormInputs = [];
      var formInput = null;
      for(var i = 0; i < response.data.model.schemaFields.length; i++)
      {
        formInput = await schemaFieldToFormInput(response.data.model.schemaFields[i], this.props.cookies.get('token'));
        if(formInput !== null)
        {
          if(response.data.model.schemaFields[i].managedUpdateForm)
          {
						// TODO: Added this to fix a bug that came up last minute
						// may not be the best fix
						const updateInput = {...formInput};
						updateInput.managedUpdateForm = true;
            updateFormInputs.push(updateInput);
          }
          createFormInputs.push(formInput);
        }
      }

			this.setState({
				isLoading: false,
				tableData: response.data.results,
        modelDoc: response.data.model,
				updateFormInputs: [...updateFormInputs],
        createFormInputs: [...createFormInputs],
			});
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

  loadMap = async (user) =>
	{
		console.log('\tDataManager.loadMap()');
		this.setState({ isLoading: true });
		var params =
    {
      userId: user._id,
      location:
      {
        longitude: -1,
        latitude: -1
      }
    };

		console.log(params);
		try
		{
			// Get data
      // TODO: Setup to use ApiManager instead
			var response = await ApiRequest.sendRequest("post", params, "location/map", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
        return;
			}

      console.log(response.data);

      // Need to flip lng/lat because they are stored backwards in Mongo for geospatial queries to work (odd I know)
      let usersLocation = response.data.lastLocation;
      if(usersLocation && usersLocation.coordinates)
      {
        usersLocation.coordinates = [usersLocation.coordinates[1], usersLocation.coordinates[0]];
      }

			this.setState({
				isLoading: false,
				geofenceAreas: response.data.results,
        usersLocation: response.data.lastLocation,
        mapVisible: true
			});
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}


  addGeofenceArea = async ({  longitude,
                              latitude,
                              note,
                              radius,
                              type,
                              image }) =>
	{
		console.log('\tDataManager.addGeofenceArea()');
		this.setState({ isLoading: true });

    console.log(image);

    // Add file
		try
		{
      const formData = new FormData();
      formData.set('model', 'geofencearea');
      formData.set('location', ('[' + longitude + ', ' + latitude + ']'));
      formData.set('note', note);
      formData.set('radius', radius);
      formData.set('type', type);

      // Image is optional
      if(image)
      {
        formData.append('image', image);
      }

			var response = await ApiRequest.sendRequest("post", formData, "data/create", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
        return false;
			}

      console.log(response.data);

      // TODO: Add to genfeoceAreas
      const geofenceAreas = [...this.state.geofenceAreas];
      geofenceAreas.push(response.data.results);

			this.setState({
				isLoading: false,
				geofenceAreas: geofenceAreas
			});
      return true;
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
      return false;
		}
	}

  /**
    Determine if column should be shown in attachable drop down
    @param  {String}  name  The column name to check
    @param  {String}  fieldNameInPtr  The name of the field in attachable record that has will object ID in it. Want to filter that out
    @returns  {Bool}  if it is valid or not
  */
  isValidAttachableColumnName = (name, fieldNameInPtr) =>
  {
    console.log(name);
    // TODO: Make backend remove these from the object and format date
    const notAttachable = ['_id', 'createdOn', 'updatedOn', 'isDeleted', 'createdBy', '__v', fieldNameInPtr];
    return (notAttachable.indexOf(name) === -1);
  }


	/**
		This will check to see if there are any other model documents that we can attach this to
    @param  {Mongo.Document}  model   Model
    @returns {Array.<FormInput>} An array of drop down form inputs representing all valid records we can attach the newly created record to
	*/
	findAttachable = async (model) =>
	{
		// Find any schemaFields with type = "reference" and reference = model.name and isArray = true
		// Query models to see who schemafield belongs to
		// Query model on that schemaField[name] = model.name
		// Query model documents and display in drop down to choose to attach to
		const params = { model: this.props.model};
		//console.log(params);
		try
		{
			// Get data
			const response = await ApiRequest.sendRequest("post", params, "data/find-attachable", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
        return;
			}

			const formInputs = [];

			// Iterate attachable models
			const keys = Object.keys(response.data.results);
			for(let i = 0; i < keys.length; i++)
			{
        const fieldNameInPtr = keys[i].substr(keys[i].indexOf('_') + 1, keys[i].length);

				// Build option drop down list of records from these models we can attach to
				const options = response.data.results[keys[i]].map( (record) =>
	  		{
					const recordProps = Object.keys(record);
          console.log(recordProps);
					let textToDisplay = "";
					for(let j = 0; j < recordProps.length; j++)
					{
						// Don't show object Id
						if(this.isValidAttachableColumnName(recordProps[j], fieldNameInPtr))
						{
              console.log(recordProps[j]);
							//console.log(record[recordProps[j]]);
							textToDisplay = textToDisplay.concat(record[recordProps[j]]);
							if(j != recordProps.length -1 && this.isValidAttachableColumnName(recordProps[i+1], fieldNameInPtr))
							{
								textToDisplay = textToDisplay.concat(':');
							}
						}
						//console.log(textToDisplay);
					}

	  			return {text: textToDisplay, value: record._id};
	  		});

				const formInput =
				{
					label: 'Attach to ' + keys[i].substr(0, keys[i].indexOf('_')) + ' record using field \"' + fieldNameInPtr + '\"',
					tooltip: 'Leave this blank to ignore it.\nThe selected record in the drop down has a reference field which is an array of type ' + model.name + '.\nThe record you are creating now will be added to the selected records reference array field.',
					id: '_attach_' + keys[i],
					element: 'select',
					type: 'select',
					required: '',
					value: '',
					options: options
				};
				formInputs.push(formInput);
			}

      console.log('DataManager.findAttachable results: ' + JSON.stringify(formInputs, null, 2));
			return formInputs;
		}
		catch(err)
		{
			console.log(err);
			return null;
		}
	}

	tableDidStartLoading = (action) =>
  {
		switch(action)
		{
			case 'update':
				break;
			case 'delete':
				break;
			default: break;
		}
    this.setState({ isLoading: true });
  }

	/**
			@param 	{JSON}		action 		action.type = 'delete, update' and action.data = newly returned data
			@param 	{String}	message 	Message returned from API
			@param 	{String}	error 		Error message or null if not contained

			action:
			{
				type: 'delete',
				data: {record}
			}

	*/
	tableDidFinishLoading = (action, message, error) =>
	{
    console.log(error);
		try
		{
			if(error === null || error === "" || error === undefined)
			{
				let modifiedData = this._table.current.handleAction(action, this.state.tableData);
				this.setState({ isLoading: false, tableData: modifiedData });
				this.props.showAlert(true, 'Success', message, 'success');
			}
			// Failed
			else
			{
				console.log(error);
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', error, 'danger');
			}
		}
		catch(err)
		{
			console.log(err + "\nStack:\n" + err.stack);
		}
	}

  // MARK: - Buttons
  mapButton = ({ selected }) =>
  {
    return (
      <MapButton
        data={this.state.tableData}
        cookies={this.props.cookies}
        tableDidStartLoading={this.tableDidStartLoading}
        tableDidFinishLoading={this.tableDidFinishLoading}
        onClick={() =>
        {
          console.log(selected);
          this.loadMap(selected[0]);

          // Query for user's nearby geofences and user's current location
          // set map to users location with different marker on them and show geofences near them along with ability to create alert buttton with text and image upload option

          //this.setState({ showingPayments: true, activeVendorId: data[0] });
        }}
      />
    );
  }


	// MARK: - Render
  componentDidUpdate()
  {
    if(this._refreshData)
    {
      this._refreshData = false;
      this.loadData();
    }
  }

  shouldComponentUpdate(nextProps, nextState)
  {
    // Signal in componentdidUpdate that we need to do more work
    if(this.props.model !== nextProps.model)
    {
      this._refreshData = true;

      // Clear table selection since data will be altered
      if(this._table && this._table.current)
      {
        this._table.current.clearAll();
      }
    }

    return (this.props.model !== nextProps.model ||
            this.props.siteManager !== nextProps.siteManager ||
            this.state.isLoading !== nextState.isLoading ||
            this.state.updateFormInputs !== nextState.updateFormInputs ||
            this.state.createFormInputs !== nextState.createFormInputs ||
            this.state.mapVisible !== nextState.mapVisible ||
            this.state.geofenceAreas !== nextState.geofenceAreas);
  }

	render()
	{
		console.log('\tDataManager.render()'); //'\nCreate form inputs:\n' + JSON.stringify(this.state.createFormInputs, null, 2));
    //console.log('DataManager.state.createFormInputs');
    console.log('Model name: ' + (this.state.modelDoc ? this.state.modelDoc.name : 'null'));
    const user = this.props.cookies.get('user');
  	return (
		<>
			<FormLoader isLoading={this.state.isLoading}/>
      {this.state.modelDoc &&
      !this.state.mapVisible &&
			<Table
				ref={this._table}
				data={this.state.tableData}
				headers={this.state.modelDoc.tableProperties.headers}
				model={this.state.modelDoc.name}

				selectAllEnabled={true}
				multiSelectEnabled={true}

				defaultSort={this.state.modelDoc.tableProperties.defaultSort}
				sortEnabled={true}

				tableDidStartLoading={this.tableDidStartLoading}
				tableDidFinishLoading={(action, message, error) => this.tableDidFinishLoading(action, message, error)}

				title={this.state.modelDoc.name + ' records'}

				isDeleteAvailable={true}

				isUpdateAvailable={true}
				updateFormInputs={this.state.updateFormInputs}

				isCreateAvailable={this.state.modelDoc.permissions.create.indexOf(user.authorization.type) !== -1}
				createFormInputs={this.state.createFormInputs}

        isCsvAvailable={true}

				cookies={this.props.cookies}
				siteManager={this.props.siteManager}

        customButton1={this.state.modelDoc.name === 'user' ? this.mapButton : null}
			/>}

      {this.state.mapVisible &&
      <GoogleMap
        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        usersLocation={this.state.usersLocation}
        notificationManager={this.props.notificationManager}
        addGeofenceArea={this.addGeofenceArea}
        geofenceAreas={this.state.geofenceAreas}
        updateGeofenceArea={(idx, showing) =>
        {
          const geofenceAreas = [...this.state.geofenceAreas];
          geofenceAreas[idx].showing = showing;
          this.setState({ geofenceAreas: geofenceAreas });
        }}
        closeMap={() =>
        {
          this.setState({ mapVisible: false });
        }}
      />}
		</>
		);
	}
}
