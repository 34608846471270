import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import StyledComponent from 'styled-components';
import GoogleMapReact from 'google-map-react';
import CreateMarker from './createMarker';
import UserMarker from './userMarker';
import GeofenceMarker from './geofenceMarker';

const RADIUS_SIZE = 500
const GOOGLE_MAPS_API_KEY = 'AIzaSyB1qBa4Fo-5_v3GcPR5BZzKkDrG4V9MWTA';

 export default class GoogleMap extends React.Component
 {
   // Refs
   _circle = null;
   _mapRef = null;
   _mapsRef = null;

   _css = null;

   // MARK: - Constructor
   constructor(props)
   {
     console.log('Map()');
     super(props);

     this.state =
     {
       createLocation:
       {
         coordinates: props.usersLocation && props.usersLocation.coordinates ?
                      [props.usersLocation.coordinates[0], props.usersLocation.coordinates[1]] :
                      []
       },
       isCreating:      false,
       isMapDraggable:  true,
       radius:          RADIUS_SIZE,
       note:            '',
       type:            null,
       image:           null,
     };

     this._css = this.styleComponent();
   }

    // MARK: - Event handlers
    // Update parent
    onChange(lat, lng)
    {
      const createLocation = {...this.state.createLocation};
      createLocation.coordinates[0] = lat;
      createLocation.coordinates[1] = lng;

      this.setState({ createLocation: createLocation });
    }

    radiusOnChange(radius)
    {
      this.setState({ radius: radius });
    }


    // MARK: - Helpers
    setStateAsync(state)
    {
      return new Promise((resolve) =>
      {
          this.setState(state, resolve)
      });
    }

    // MARK: - Marker related
    onMarkerMove = (childKey, childProps, mouse) =>
    {
    }

    apiIsLoaded = (map, maps) =>
    {
      this._mapRef = map;
      this._mapsRef = maps;
      this._circle = new this._mapsRef.Circle({
        strokeColor: "001e57",
        strokeOpacity: 0,
        strokeWeight: 2,
        fillColor: "#bbd0ff",
        fillOpacity: 0,
        map: this._mapRef,
        center: {
          lat: this.state.createLocation.coordinates[0],
          lng: this.state.createLocation.coordinates[1]
        },
        radius: parseFloat(this.state.radius),
        editable: false,
        draggable: false,
      });
      this._mapsRef.event.addListener(this._circle, 'radius_changed', () =>
      {
        console.log('radius_changed');
        this.radiusOnChange(this._circle.getRadius());
      });
      this._mapsRef.event.addListener(this._circle, 'center_changed', () =>
      {
        console.log('center_changed');
        this.onChange(this._circle.getCenter().lat(), this._circle.getCenter().lng());
      });
    };

	   // MARK: - Render
    shouldComponentUpdate(nextProps, nextState)
    {
        return (this.props.usersLocation !== nextProps.usersLocation ||
                this.state.createLocation !== nextState.createLocation ||
                this.state.radius !== nextState.radius ||
                this.state.isCreating !== nextState.isCreating ||
                this.state.note !== nextState.note ||
                this.state.image !== nextState.image ||
                this.state.type !== nextState.type ||
                this.props.geofenceAreas !== nextProps.geofenceAreas);
    }

    render()
    {
      console.log(this.props.usersLocation);
      console.log(this.state.createLocation);

      let geofenceAreaTypes = this.props.notificationManager.getGeofenceAreaTypes();

      const formInput = (this.props.usersLocation ?
      <div style={{ height: '600px', width: '100%', marginTop: '30px' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          draggable={this.state.isMapDraggable}
          defaultCenter={
          {
            lat: this.props.usersLocation.coordinates[0],
            lng: this.props.usersLocation.coordinates[1],
          }}
          defaultZoom={14}
          onChildMouseDown={() => this.setState({ isMapDraggable: false })}
          onChildMouseUp={() =>
          {
            this.setState({ isMapDraggable: true });
          }}
          onChildMouseMove={this.onMarkerMove}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps}) => this.apiIsLoaded(map, maps)}
        >
          <UserMarker
            lat={this.props.usersLocation.coordinates[0]}
            lng={this.props.usersLocation.coordinates[1]}
            text="Users Location"
          />
          {this.state.isCreating &&
          <CreateMarker
            lat={this.state.createLocation.coordinates[0]}
            lng={this.state.createLocation.coordinates[1]}
            text="Movable Marker"
          />}
          {this.props.geofenceAreas.map( (geofenceArea, i) =>
          {
            return (
              <GeofenceMarker
                key={`geofencearea-${i}`}
                lat={geofenceArea.location.coordinates[1]}
                lng={geofenceArea.location.coordinates[0]}
                text={geofenceArea.note}
                color={geofenceArea.type.color}
                image={geofenceArea.image}
                showing={geofenceArea.showing}
                onClick={() =>
                {
                  this.props.updateGeofenceArea(i, !geofenceArea.showing);
                }}
              />
            )
          })}
        </GoogleMapReact>
      </div> : <p>{'Users location required'}</p>
    );

    // Controls if this input is an entire row to itself or not
    return (

		<this._css>

      {/* Map */}
			{formInput}

      {/* Alert type */}
      {this.state.isCreating &&
      <div className="btn-group space" role="group">
        {geofenceAreaTypes.map( (geofenceAreaType, i) =>
        {
          console.log(geofenceAreaType);
          return (
            <button
              key={`geofenceAreaType-${i}`}
              type="button"
              className={`btn btn-${this.state.type && geofenceAreaType._id.toString() === this.state.type ? 'primary' : 'secondary'}`}
              onClick={(e) =>
              {
                this.setState({ type: geofenceAreaType._id.toString() });
              }}
            >{geofenceAreaType.label}</button>
          )
        })}
      </div>}

      {/* Note */}
      {this.state.isCreating &&
      <div className="input-group mb-3 space">
        <div className="input-group-prepend">
          <span className="input-group-text" id="note-label">Note</span>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder="Note"
          aria-label="Note"
          aria-describedby="note-label"
          value={this.state.note}
          onChange={(e) =>
          {
            this.setState({ note: e.target.value });
          }}
        />
      </div>}

      {/* Image */}
      {this.state.isCreating &&
      <div className="input-group mb-3 space">
        <div className="custom-file">
          <input
            type="file"
            className="custom-file-input"
            id="inputGroupFile01"
            accept=".jpg, .png, .jpeg"
            onChange={(e) =>
            {
              this.setState({ image: e.target.files[0] });
            }}
          />
          <label className="custom-file-label" htmlFor="inputGroupFile01">{this.state.image ? 'Replace image' : 'Choose image'}</label>
        </div>
      </div>}

      {/* Add alert button */}
      <button
        id={'add-btn'}
        type="submit"
        className="btn btn-primary btn-block space"
        onClick={async(e, input) =>
        {
          // Add geofence area
          if(this.state.isCreating)
          {
            const result = await this.props.addGeofenceArea({
              longitude: this.state.createLocation.coordinates[1],
              latitude: this.state.createLocation.coordinates[0],
              note: this.state.note,
              radius: this.state.radius,
              type: this.state.type,
              image: this.state.image
            });

            // Reset after success
            if(result)
            {
              this.setState({
                note: '',
                radius: RADIUS_SIZE,
                type: null,
                image: null,
                isCreating: false
              });
              this._circle.setOptions({ fillOpacity: 0, strokeOpacity: 0, editable: false });
            }
          }
          // Show circle and start create mode
          else
          {
            this._circle.setOptions({ fillOpacity: 0.3, strokeOpacity: 0.8, editable: true });
            this.setState({ isCreating: true });
          }
        }}
      >{this.state.isCreating ? 'Save Alert' : 'Enter Create Mode'}</button>

      {/* Cancel button */}
      {this.state.isCreating &&
      <button
        id={'cancel-btn'}
        type="submit"
        className="btn btn-danger btn-block space"
        onClick={(e, input) =>
        {
          // Show circle and start create mode
          this._circle.setOptions({ fillOpacity: 0, strokeOpacity: 0, editable: false });
          this.setState({ isCreating: false });
        }}
      >{'Cancel'}</button>}

      {/* Close button */}
      {!this.state.isCreating &&
      <button
        id={'cancel-btn'}
        type="submit"
        className="btn btn-danger btn-block space"
        onClick={(e, input) =>
        {
          this.props.closeMap();
        }}
      >{'Close Map'}</button>}
		</this._css>);
	}

  styleComponent = () =>
	{
		return StyledComponent.div`
			.space
      {
        margin-top: 10px;
      }
		`;
	}
}
