import React, { Component } from 'react';
import GoogleLoginButton from './googleLoginButton';
import { Colors } from '../../constant';

import StyledComponent from 'styled-components';

export default class LoginContainer extends Component
{
  // MARK: - Data fields
  _css = null;

  // MARK: - Constructor
  constructor(props)
  {
    super(props);
    this.state =
    {
      accessToken: '',
      externalId: '',
      email: '',
      photo: '',
      firstName: '',
      lastName: '',
      password: '',
    };

    this._css = this.styleComponent();
  }

  componentDidMount()
  {
  }

  login = async (updateParams) =>
  {
    console.log(updateParams);
    if(updateParams.source === 'google')
    {
      const params = {...updateParams};
      params.cb = () =>
      {
        // Clear source
        this.props.updateFormInput('source', '');
        this.setState({ source: '' });
      };
      await this.props.login(params);
    }
  }


  // MARK: - Render
  render()
  {
    console.log('\tLoginContainer.render()');
    console.log(this.props);
    return (
      <this._css>

        <div
          className={'loginContainer'}
        >

          <GoogleLoginButton
            formInput={{name: 'google-login-btn'}}
            updateMasterState={this.props.updateMasterState}
            login={this.login}
            key={'google-login-btn-container'}
            showAlert={this.props.showAlert}
          />

        </div>
      </this._css>);
  }


  styleComponent = () =>
  {
    return StyledComponent.div`
    .loginContainer
    {
      flex: 1;
      justify-content: center;
    }
    `;
  }
};
