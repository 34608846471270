import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const BG_COLOR = '#E74C3C';

const Wrapper = ({ text }) => <div
                                style={{
                                  backgroundColor: BG_COLOR,
                                  width: '30px',
                                  height: '30px',
                                  borderRadius: '30px',
                                }}
                              >
                                <p style={{color: '#000'}}>{'User'}</p>
                              </div>

const UserMarker = ({ text, onClick }) =>
(
  <Wrapper
    alt={text}
    onClick={onClick}
  />
);

UserMarker.defaultProps =
{
  onClick: null,
};

UserMarker.propTypes =
{
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default UserMarker;
