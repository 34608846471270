import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const Wrapper = () => <img
                        src={require('../../asset/push-pin.png')}
                        alt='Marker'
                        style={{ transform: 'translate(-50%, -50%)' }}
                      />

const CreateMarker = ({ text, color, onClick }) =>
(
  <Wrapper
    alt={text}
    onClick={onClick}
  />
);

CreateMarker.defaultProps =
{
  onClick: null,
};

CreateMarker.propTypes =
{
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default CreateMarker;
