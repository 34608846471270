import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap/dist/css/bootstrap-theme.css';

const initialState =
{
	userState:
	{
		username: '',
		homepage: false
	}
};

const reducer = (state, action) =>
{
	console.log('State: ' + JSON.stringify(state));
	console.log('Action: ' + JSON.stringify(action));
	switch (action.type)
	{
		case 'login':
			return{
				...state,
				userState: action.newUserState
			};

		default:
			return state;
	}
};


ReactDOM.render(
	<CookiesProvider>
		<App />
	</CookiesProvider>,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
