import React, { Component } from 'react';
import ApiRequest from '../../api/request.js';
import { Colors } from '../../constant';
import { OauthManager } from '../../managers';
import StyledComponent from 'styled-components';

export default class GoogleLoginButton extends Component
{
  _css = null;
  _oauthMgr = null;
  constructor(props)
  {
    console.log('\tGoogleLoginButton()');
    super(props);

    this._oauthMgr = OauthManager.GetInstance();
    this._oauthMgr.addListener('GoogleLoginButton',
    (msg) =>
    {
      console.log(msg);
      if(msg.source === 'google' && msg.token !== null)
      {
        this.props.login(msg);
      }
    });

    this._css = this.styleComponent();
  }

  getUrl = async() =>
  {
    this.props.updateMasterState({ isLoading: true });
    const response = await ApiRequest.sendRequest('post',
                                                  {source: 'google'},
                                                  'oauth/auth-url');
    console.log(response.data);
    if(response.data.error !== null)
    {
      this.props.updateMasterState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
      return;
    }

    this.props.updateMasterState({ isLoading: false });

    var win = window.open(response.data.result, '_self');
  };

  render()
  {
    return (
      <this._css
        onClick={async() => this.props.isLinked ? console.log("Already linked") : this.getUrl()}
      >
        <img
          className={'image'}
          src={require('../../asset/google-icon.png')}
        />
        <p
          className={'buttonText'}
        >{'Sign in with Google'}</p>
      </this._css>
    );
  }

  styleComponent = (siteManager) =>
  {
    return StyledComponent.div`
    width: 100%;
    height: 100px;
    background-color: Colors.transparent;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-color: Colors.plainGray5;
    border-width: 1;
    border-radius: 4;
    display: flex;

    .image{
      height: 100%;
      width: 100px;
      padding: 0;
    }
    .buttonText{
      font-family: Arial;
      font-size: 22px;
      text-align: left;
      margin-left: 10px;
      color: Colors.plainGray5;
      width: 100%;
      flex: 1;
      justify-content: center;
    },
    `;
  }
};
