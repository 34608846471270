import {Component, Data, User} from '../interact';
/**
  This will handle all API calls so they are not randomly made throughout the application
*/
export default class ApiManager
{
    static #singleton = null;
    #apiToken = null;


    // Singleton
    /**
      Initializer so we can get an API token
      @returns {ApiManager}
     */
    static Init(apiToken = null)
    {
      // Initialize and load styles
      if(ApiManager.singleton == null)
      {
        ApiManager.#singleton = new ApiManager();
        console.log('\t\tApiManager.Init()');
      }
      if(apiToken !== null)
      {
        ApiManager.#singleton.#apiToken = apiToken;
      }
      return ApiManager.#singleton;
    }

    /**
      Interact with a route (social|data) and perform action (like|report|create|delete|update)
      @note   Params come in as params object
      @param  {String}  route   (social|data)
      @param  {String}  action  (like|dislike) (create|delete|update)
      @param  {JSON}  params  Params of request
    */
    static async SendRequest(params)
    {
      if(!ApiManager.#singleton)
      {
        console.log('what tf');
      }
      if(params.route === 'component')
      {
        return Component.Interact(params, ApiManager.#singleton.#apiToken);
      }
      if(params.route === 'data')
      {
        return Data.Interact(params, ApiManager.#singleton.#apiToken);
      }
      else if(params.route === 'user')
      {
        return User.Interact(params, ApiManager.#singleton.#apiToken);
      }
    }
}
