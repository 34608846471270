import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const InfoWindow = ({ text, image }) => {
  const infoWindowStyle = {
    position: 'relative',
    bottom: 150,
    left: '-45px',
    width: 220,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 100,
  };

  return (
    <div style={infoWindowStyle}>
      <div style={{ fontSize: 16 }}>
        {text}
      </div>
      <div style={{ fontSize: 14, color: 'grey' }}>
        <img src={image} style={{width: '200px'}}/>
      </div>
    </div>
  );
};


const Wrapper = ({ text, color, image, onClick, showing }) =>
                                              <div
                                                style={{
                                                  backgroundColor: color,
                                                  width: '30px',
                                                  height: '30px',
                                                  borderRadius: '30px',
                                                }}
                                                onClick={() =>
                                                {
                                                  console.log('Clicked');
                                                  onClick();
                                                }}
                                              >
                                                {showing && <InfoWindow
                                                              text={text}
                                                              image={image}

                                                            />}
                                              </div>

const GeofenceMarker = ({ text, color, onClick, image, showing }) =>
(
  <Wrapper
    text={text}
    color={color}
    onClick={onClick}
    image={image}
    showing={showing}
  />
);


GeofenceMarker.propTypes =
{
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default GeofenceMarker;
