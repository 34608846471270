'use strict';

export default
{
  form:
  {
    formInputLabel:
    {
      color: '#235373'
    },
    popupDialog:
    {
      question:
      {
        color: '#575757'
      },
      okayBtn:
      {
        color: '#235373'
      }
    },
    submitBtn:
    {
      color: '#70a040'
    }
  }
};
