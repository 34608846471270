import React, { Component } from 'react';

import FormLoader    from '../../components/Form/FormLoader';
import { ApiManager, NotificationManager, OauthManager } from '../../managers';
import ApiRequest    from '../../api/request.js';


export default class Api extends Component
{
  // MARK: - Constructor
  constructor(props)
  {
    console.log('\tApi()');
    super(props);

    const qs = props.location.search.substr(props.location.search.indexOf('?') + 1);
    let code = this.getVariableValue(qs, 'code');
    let source = this.getVariableValue(qs, 'source');

    this.state =
    {
      isLoading: false,
      code: code,
      source: source
    };
  }

  async componentDidMount()
  {
    await this.oauthConvert(this.state.source, this.state.code);
  }

  getVariableValue = (qs, variable) =>
  {
    let vars = qs.split('&');
    for (var i = 0; i < vars.length; i++)
    {
      let pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable)
      {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  oauthConvert = async(source, code) =>
  {
    this.setState({ isLoading: true });

    const params =
    {
      source: source,
      code: code
    };
    console.log('[API.oauthConvert] calling API oauth/convert' );
    const response = await ApiRequest.sendRequest('post',
                                                  params,
                                                  'oauth/convert');
    console.log('[API.oauthConvert] response:' + JSON.stringify(response.data) );


    if(response.data.error !== null)
    {
      this.setState({ isLoading: false });
      this.props.showAlert('Un-oh', response.data.error);
      return;
    }

    this.setState({ isLoading: false });

    // Need to tell GoogleLoginButton that we are done
    if(response.data.results.source === 'google')
    {
      this.thirdPartyLogin(response.data.results);
    }
  }

  thirdPartyLogin = async({ accessToken, externalId, email, source, firstName, lastName, photo, password, url, cb }) =>
  {
    this.setState({ isLoading: true });

    const params =
    {
      accessToken: accessToken,
      externalId: externalId,
      email: email,
      source: source,
      firstName: firstName,
      lastName: lastName,
      photo: photo,
      password: password,
      url: url,
      isSimulator: false,
    };

    console.log( `[Auth.thirdPartyLogin] calling API with params ${JSON.stringify(params)}`);

    // calls server oauth-controller.login method
    const response = await ApiRequest.sendRequest('post',
                                                  params,
                                                  'oauth/login');

    console.log( `[Auth.thirdPartyLogin] response.data: ${JSON.stringify(response.data)}`);

    if(response.data.error !== null)
    {
      this.setState({ isLoading: false });
      this.props.showAlert('Un-oh', response.data.error);
      return;
    }

    this.props.cookies.set(	'token',
                            response.data.token,
                            { path: '/' });
    this.props.cookies.set(	'user',
                            response.data.user,
                            { path: '/' });

    ApiManager.Init(this.props.cookies.get('token'));

    // Save oauth token
    if(response.data.oauthTokens)
    {
      OauthManager.GetInstance().setOauthTokens(response.data.oauthTokens);
    }

    // Initialize NotificationManager
    NotificationManager.GetInstance().init(response.data.token);

    this.setState({ isLoading: false }, () =>
    {
      this.props.history.push('/home');
    });
  }

  render()
  {
    console.log('\tApi.render()');
    return (
      <>
        <FormLoader
          isLoading={this.state.isLoading}
        />
      </>
    );
  }
}
