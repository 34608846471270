import Axios 	from 	'axios';
import Config 	from 	'../config';
const uuidv4 = require('uuid/v4');
const { detect } = require('detect-browser');
const browser = detect();

class ApiRequest
{
	/**
	 * Send HTTP request (get or post).
	 *
	 * @param 	{string} 	method 	get|post
	 * @param 	{Object}	data 	JSON formatted data to be the body of the request
	 * @param 	{string}	path 	Route path for backend API
	 *
	 * @return 	{Promise}	A promise containing the result of the request
	 */
	static async sendRequest(method, params, path, token = "", contentType = 'application/json')
	{
		try
		{
			// Session ID
			var uuid = localStorage.getItem('uuid');
			if(uuid === null)
			{
					uuid = uuidv4();
					localStorage.setItem('uuid', uuid);
			}

			let accessToken = token;
			if(accessToken === '')
			{
				accessToken = "guest";
			}

			let config =
			{
				method: 	method,
				url:		Config.API_SERVER_URL + path,
				headers:
				{
					'Content-Type': contentType,
					'x-access-token': accessToken,
					'x-device-id': 	uuid,
					'x-request-source': 'web',
					'x-device-service-name': browser.name,
					'x-device-service-version': browser.version
				},
				data:		params
			};
			const response = await Axios.request(config);
			return response;
		}
		catch(err)
		{
			console.error(err);
			throw err;
		}
	}
}


export default ApiRequest;
