import ApiRequest from '../api/request.js';

export default class OauthManager
{
  static singleton = null;
  #_oauthTokens = null;
  #_showAlert = null;
  #_updateGlobalState = null;
  // Holds {id: name, cb: callback}
  #_listeners = [];

  // Singleton
  /**
    @returns {OAuthManager}
   */
  static GetInstance()
  {
    // Initialize
    if(OauthManager.singleton == null)
    {
      OauthManager.singleton = new OauthManager();
    }
    return OauthManager.singleton;
  }

  setAlertHandler(handler)
  {
    this.#_showAlert = handler;
  }

  setGlobalStateHandler(handler)
  {
    this.#_updateGlobalState = handler;
  }

  /**
    Retrieve oauth tokens
  */
  getOauthTokens()
  {
    return this.#_oauthTokens;
  }

  setOauthTokens(tokens)
  {
    this.#_oauthTokens = tokens;
  }

  addOauthToken(source, token)
  {
    this.#_oauthTokens[source] = token;
    this.notifyListeners({ source: source.replace('Token', ''), token: token });
  }

  hasOauthToken()
  {
    const keys = Object.keys(this.#_oauthTokens);
    console.log(keys);
    for(let i = 0; i < keys.length; i++)
    {
      if(this.#_oauthTokens[keys[i]] !== null)
      {
        return true;
      }
    }
    return false;
  }



  async removeToken(params)
  {
    const response = await ApiRequest.sendRequest('post',
                                                  params,
                                                  'oauth/remove-token');
    console.log(response.data);
    if(response.data.error !== null)
    {
      //this.setState({ isLoading: false });
      this.#_showAlert('Un-oh', response.data.error);
      return false;
    }

    this.#_oauthTokens[params.source + 'Token'] = null;

    this.notifyListeners({ source: params.source, token: null });
    return true;
  }


  // MARK: - Listener related
  addListener(id, cb)
  {
    this.#_listeners.push({ id: id, cb: cb });
  }

  removeListener(listenerId)
  {
    for(let i = 0; i < this.#_listeners.length; i++)
    {
      if(this.#_listeners[i].id === listenerId)
      {
        this.#_listeners.splice(i, 1);
        break;
      }
    }
  }

  notifyListeners(message)
  {
    for(let i = 0; i < this.#_listeners.length; i++)
    {
      console.log('OauthManager.notifyListeners(' + this.#_listeners[i].id + ')');
      this.#_listeners[i].cb(message);
    }
  }
}
