import Tooltip from '@material-ui/core/Tooltip';
import MapIcon from '@material-ui/icons/Map';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import ApiRequest    from '../../api/request.js';

class MapButton extends React.Component
{
  render()
  {
    return (
      <Tooltip title="View alert map">
        <IconButton onClick={async (selected) =>
          {
            console.log('MapButton.onClick()');
            this.props.onClick(selected);
          }} aria-label="row">
          <MapIcon />
        </IconButton>
      </Tooltip>
    );
  }
}


export default MapButton;
