import React from 'react';
import FormError from './FormError';
import FormFooter from './formFooter';
import FormInput from './FormInput';
import { GoogleMap } from '../googleMap';
import TooltipDialog from './tooltipDialog';
import Colors from '../../constant/colors';
import { LoginContainer } from '../loginContainer';
import { Link }  from 'react-router-dom';
import StyledComponent from 'styled-components';

/**
   Form that has validation on fields
    @param  {Int}       columns       Number of columns (default = 1)
      @note   -1 value for columns indicates columns/rows are controlled by form input (col/row props)
  	@param 	{function} 	formOnChange 	Callback when a form value changes
    @param  {String}    theme     The theme to use (default|main) (default='default')
    @param  {bool}        showErrorList   Flag to show error list on top of form or not
    @param  {Bool}      dynamicInputs   Flag that indicates parent will alter the form inputs dynamically so we need extra validations
  	@param	[{JSON}]	formInputs 	   Array of JSON objects representing form inputs
   			     label: 		Label text to show above input
   			     id:			ID of inputs so it can be accessed by parent later
   			     type: 		    Type of input (text/password/email/number/checkbox)
             class:         Class names
   			     element: 	  The element type (input/textarea/select)
             options: (Only available for select element)
   			     placeholder: The placeholder text {default=""}
           	 value:       Value
             requireValidForm  Only available on submit types to control if they can be used if the form is in an invalid state {default=true}
           	 onClick:     Onclick javascript handler
				     required: 	 Required to submit form {default=false}
         	   validation 	 JSON object with validation algorithm return true/false
			          algorithm: email|range
                requirement: text that shows when requirement is not met
  		          params: [range: min,max]
              row:  The row number this should be in the form (allows specifying multiple fields in one row) default: blank
              col:  Column size (1 through 12 Bootstrap style) default: blank

  	State:
  		@param	{bool} 	      isFormValid		If form is valid to submit according to validation rules on each field
      @param  {Map}         formErrors      Key=>ID Value=>ErrorMessage holds all form errors
      @param  {Object}      tooltip     Tooltip related properties
        @param  {Bool}  isOpen  If tooltip modal is showing or not
        @param  {String}  question  Tooltip Question
        @param  {String}  message  Tooltip message
      @param  {}

*/
 export default class FormValidated extends React.Component
 {
    // MARK: - Data fields
    _btns           = new Map();
    formErrors     = [];    // Error components created from state data
    fieldRefs      = new Map();   // Key=>ID Value=Reference to child input

    _css = null;
    _formFooterCss = null;
    _formFooter     = null; // Can only be one form footer per form

    _formTypes = ['_form_footer_', 'space', 'link'];

	 // MARK: - Constructor
     constructor(props)
     {
        super(props);

        var state =
        {
          tooltip:
          {
            isOpen: false,
            question: '',
            message: ''
          }
        };

        // Initalize requirements and input states

        // TODO: This only works if form inputs are given on first render,
        // if they are loaded and passed in after an API call this isn't the case
        // need to figure out how to resolve that issue so we don't need to
        // conditionally render the form component once inputs are existing
        var formErrors = {};
        if(props.formInputs)
        {
          props.formInputs.forEach( (data) =>
          {
            if(this._formTypes.indexOf(data.type) === -1)
            {
              if(data.required === 'required' &&
                (data.type !== 'button' && data.type !== 'submit'))
              {
                const emptyError = '-EV';// + data.id;// + '_type:' + data.type;
                console.log('Error ID: ' + data.id + ' set to: ' + emptyError);
                formErrors[data.id] = emptyError;   /* EV is empty value */
              }
              state[data.id] = data.value ? data.value : '';

              if(data.type !== 'submit' && data.type !== 'button')
              {
                this.fieldRefs.set(data.id, React.createRef());
              }
              else
              {
                this._btns.set(data.id, React.createRef());
              }
            }
          });
        }

        state['formErrors']     = formErrors;
        state['isFormValid']    = false;

        this.state = state;


        // Style component
        this._css = this.styleComponent(props.siteManager);

        console.log("\tFormValidated()");
    }

    componentDidMount()
    {
      if(this.props.validateOnInit)
      {
        this.validateOnInit();
      }
    }

    // MARK: - onChange handlers
	/**
	  *	Handle a form field input value changing
	  *
	  *	@param	{JSON}	id, value, validation
	  */
	formInputOnChange = (change, notifyParent = true) =>
	{
    //console.log(JSON.stringify(change));
    //console.log('Form.onChange()\n' + change.id + ' set to ' + change.value);
		// Update internal state
		this.setState({ [change.id]: change.value });

		// Validate field
		this.validateField(change.id, change.value, change.validation, change.type);

    // If radius changed via map then update radius field
    if(change.id === 'radius' && change.forcedRadiusChange)
    {
      const fieldRef = this.fieldRefs.get(change.id);
      if(fieldRef && fieldRef.current !== null)
      {
        fieldRef.current.updateValue(change.value);
      }
    }

		// Update parent page of change in form state
    if(notifyParent)
    {
		  this.props.formOnChange(change, this.state.isFormValid);
    }
	}


    // MARK: - Validation
	/**
	 * Validate form field
	 *
	 * @param   {string}    id            		Id of field
	 * @param 	{string} 	value 				Value in field to validate
	 * @param 	{JSON}		validation 			JSON object with validation algorithm return true/false
   @param   {String}    type    The type of field this is
	 *				algorithm: email|range
	 *				params: [range: min,max]
	 *
	 * Return empty string if valid or error message if invalid
	 *
	 */
	validateField(id, value, validation, type)
	{
		var errorMessage = '';
    if(validation && validation.algorithm)
    {
      //console.log('Table.validateField(' + id + ', ' + value + ")");
  		switch(validation.algorithm)
  		{
  			case 'email':
  				errorMessage = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? '' : 'This email address is invalid';
  				break;
  			case 'range':
          if(type === 'number')// Handle numeric values
          {
            if(isNaN(value))
            {
              errorMessage = id +  ' is not a valid number';
            }
            else if(value < validation.min)
            {
              errorMessage = id + ' is too short.  Please enter at least a value of ' + validation.min;
            }
            else if(value > validation.max)
            {
                errorMessage = id + ' is too long.  Please enter at most a value of ' + validation.max;
            }
          }
          else // Handle strings
          {
            if(value === undefined || value.length < validation.min)
            {
                errorMessage = id + ' is too short.  Please enter at least ' + validation.min + ' characters';
            }
            else if(value === undefined || value.length > validation.max)
            {
                errorMessage = id + ' is too long.  Please enter at most ' + validation.max + ' characters';
            }
          }
  				break;
        case 'select':
          if(value && value.length === 0)
          {
            errorMessage = id + ' must have a selection';
          }
  			default:
  				break;
  		}

  		var formErrors = {...this.state.formErrors};
      formErrors[id] = errorMessage;

      //console.log('Form errors');
      console.log(formErrors);

  		this.setState({ formErrors: formErrors });
  		this.validateForm();
    }
	}

	validateForm()
	{
    // Put errors into list on top of form
    if(this.props.showErrorList)
    {
      // Clear previous errors
      this.formErrors = [];
      this.state.formErrors.forEach( (error, id) =>
      {
        this.formErrors.push(<FormError key={id} error={error} /> )
      });
    }

    // Iterate error map to see if we have any
    var isValid = true;
    let keys = Object.keys(this.state.formErrors);
    //console.log(this.state.formErrors);
    for(let i = 0; i < keys.length; i++)
    {
      if(this.state.formErrors[keys[i]] > 0)
      {
        console.log('Error: ' + this.state.formErrors[keys[i]]);
        isValid = false;
        break;
      }
    }

    //console.log('FormValidated valid=' + isValid);
    if(this.state.isFormValid !== isValid)
    {
  	   this.setState({ isFormValid: isValid });
    }
    /**
      TODO: I want to move this into the if statement above
      and make sure the button gets updated if it's a dynamic form input only if it has to
    */
    // Iterate button map and update their validity
    var btnItr = this._btns.keys();
    var key = btnItr.next();
    var btn = null;
    while(key.value && key.done !== true)
    {
      btn = this._btns.get(key.value);
      if(btn)
      {
        //console.log('Btn on change ' + key.value);
        //console.log('Is valid: ' + isValid);
        if(btn.current !== null)
        {
          btn.current.formStateChanged(isValid);
        }
      }
      key = btnItr.next();
    }
  }

  validateOnInit()
  {
    this.props.formInputs.forEach( (data) =>
    {
      //console.log(data.id + ', ' + data.value );
      if(this._formTypes.indexOf(data.type) === -1)
      {
        this.formInputOnChange({ id: data.id, value: data.value, validation: data.validation });
      }
    });
  }


  getValueFor = (id) =>
  {
    return this.state[id];
  }


    // MARK: - Helpers
	isFormValid = () =>
	{
		return this.state.isFormValid;
	}

  // Clear fields and errors
  reset()
  {
      console.log('\tForm.reset()');

      // Clear errors and reset field values
      var formErrors = {};
      this.props.formInputs.forEach( (data) =>
      {
        if(this._formTypes.indexOf(data.type) === -1)
        {
          if(data.required === 'required' &&
            (data.type !== 'submit' && data.type === 'button'))
          {
            const emptyError = '-EV';// + data.id;// + '_type:' + data.type;
            console.log('Error ID: ' + data.id + ' set to: ' + emptyError);
            formErrors[data.id] = emptyError;   /* EV is empty value */
          }
          this.setState({ [data.id]: data.value ? data.value : '' });

          // Don't reset submit buttons value
          if(data.type !== 'submit' && data.type !== 'button')
          {
            //console.log(this.fieldRefs);
            this.fieldRefs.get(data.id).current.updateValue('');
          }
        }
      });
      this.setState({ formErrors: formErrors });

      // Iterate button map and update their validity
      var btnItr = this._btns.keys();
      var key = btnItr.next();
      var btn = null;
      while(key.value && key.done !== true)
      {
        btn = this._btns.get(key.value);
        if(btn)
        {
          btn.current.formStateChanged(false);
        }
        key = btnItr.next();
      }
  }

  /**
    Manually update form input values
    (Useful if already instantiated form inputs and manually modified their value)
    because form inputs use their state's value which is set on construction
    @param  String  id      The id of the form input
    @param  String  value    The new value to assign to it
  */
  async updateFormInputValue(id, value, validation, type )
  {
    try
    {
      console.log("FormValidated.updateFormInputValue(" + id + ',' + value + ', ' + validation + ', ' + type + ')');

      if(this._formTypes.indexOf(type) === -1)
      {
        if(type === 'submit' || type === 'button')
        {
          const btnRef = this._btns.get(id);
          if(btnRef && btnRef.current !== null)
          {
            await btnRef.current.updateValue(value);
          }
        }
        else
        {
          const fieldRef = this.fieldRefs.get(id);
          if(fieldRef && fieldRef.current !== null)
          {
            await fieldRef.current.updateValue(value);
          }
        }

        let change = {id: id, value: value, validation: validation};
        //console.log("Calling on change for " + id);
        this.formInputOnChange(change, false);
      }
    }
    catch(err)
    {
      console.log('ID: ' + id);
      throw err;
    }
  }

  /**
    Manually update all form inputs values
  */
  updateAllFormInputValues()
  {
    console.log('\tFormValidated.updateAllFormInputValues()');

    // Update form inputs
    for(var i = 0; i < this.props.formInputs.length; i++)
    {
    //  console.log(this.props.formInputs[i].value);
      if(this._formTypes.indexOf(this.props.formInputs[i].type) === -1)
      {
        this.updateFormInputValue(this.props.formInputs[i].id,
                                  this.props.formInputs[i].value,
                                  this.props.formInputs[i].validation,
                                  this.props.formInputs[i].type);
      }
    }
  }



  removeOldFormInput(oldFormInput, formErrors)
  {
    //console.log('Really removed: ' + oldFormInput.id);
    // Clear form error
    delete formErrors[oldFormInput.id];

    // Clear reference
    if(oldFormInput.type !== 'submit' && oldFormInput.type !== 'button') // Update references
    {
      this.fieldRefs.set(oldFormInput.id, null);
    }
    else
    {
      //console.log('Removing oldFormInput: ' + oldFormInput.id);
      this._btns.set(oldFormInput.id, null);
    }
  }

  addNewFormInput(newFormInput, formErrors)
  {
    // Set form error to -EV
     // EV is empty value
    const emptyError = '-EV';// + newFormInput.id + '_type:' + newFormInput.type;
    let formError = (newFormInput.value && newFormInput.value != '') ? '' : emptyError;

    // Submit/buttons cannot have empty value error
    if((newFormInput.type === 'submit' || newFormInput.type === 'button') &&
      formError === emptyError)
    {
      formError = '';
      //console.log('Reset form error value for ' + newFormInput.id);
    }
    if(formError === emptyError)
    {
      //console.log('Error ID: ' + newFormInput.id + ' set to: ' + emptyError);
    }

    if(formError === '')
    {
      delete formErrors[newFormInput.id];
    }
    else
    {
      formErrors[newFormInput.id] = formError;
    }
    // Set reference
    if(newFormInput.type !== 'submit' && newFormInput.type !== 'button') // Update references
    {
      this.fieldRefs.set(newFormInput.id, React.createRef());
    }
    else
    {
      this._btns.set(newFormInput.id, React.createRef());
    }
  }

  componentDidUpdate(prevProps)
  {
    //console.log(this.props.formInputs);
    //console.log(this.state.type);
    try
    {
      console.log('\tFormValidated.componentDidUpdate()');

      // If we do not support dynamic inputs skip all this validation
      if(this.props.dynamicInputs)
      {
        var formErrors = {...this.state.formErrors};
        var formErrorsChanged = false;

        // If we have to remove a form input we should force all inputs to update themselves
        // just to be safe
        var forceFormInputValuesUpdate = false;

        // Record all state changes here
        // then we can update at the end rather than setting off a loop of state changes
        var stateChanges = {};
        var formInputMap = {};

        // Iterate old formInputs and check if ID's have changed
        // (someone manually altered form inputs JSON)
        for(var i = 0; i < prevProps.formInputs.length; i++)
        {
          if(this._formTypes.indexOf(prevProps.formInputs[i].type) === -1)
          {

            // Check current form input for ID
            var idStillExists = false;
            var oldIdx = -1;
            for(var j = 0; j < this.props.formInputs.length; j++)
            {
              if(prevProps.formInputs[i].id && prevProps.formInputs[i].id.indexOf('_confirm_') !== -1)
              {
                //console.log('Checking ' + this.props.formInputs[j].id + '===' + prevProps.formInputs[i].id);
              }
              if(this.props.formInputs[j].id === prevProps.formInputs[i].id)
              {
                oldIdx = j;
                idStillExists = true;
                break;
              }
            }

            // Form input no longer exists in the form
            if(!idStillExists)
            {
              //console.log('Removed ID: ' + prevProps.formInputs[i].id);
              stateChanges[prevProps.formInputs[i].id] = null;
              forceFormInputValuesUpdate = true;
              this.removeOldFormInput(prevProps.formInputs[i], formErrors);
              formErrorsChanged = true;
            }
            else // Check if form input value modified by parent
            {

              //console.log('Comparing ' + prevProps.formInputs[oldIdx].value + ' == ' + this.props.formInputs[i].value + ' (' + oldIdx + ', ' + i + ')');
              if(this.props.formInputs[i] === undefined)
              {
                // Do nothing
              }
              else if(prevProps.formInputs[oldIdx] === undefined ||
                        prevProps.formInputs[oldIdx].value !== this.props.formInputs[i].value)
              {
                stateChanges[this.props.formInputs[i].id] = this.props.formInputs[i].value;
                formInputMap[this.props.formInputs[i].id] = this.props.formInputs[i];
              }
            }
          }
        }

        // Iterate new form inputs, if they didn't exist previously let's set references for them
        for(var i = 0; i < this.props.formInputs.length; i++)
        {
          if(this._formTypes.indexOf(this.props.formInputs[i].type) === -1)
          {
            // Check current form input for ID
            var idIsNew = true;
            var oldIdx = -1;
            for(var j = 0; j < prevProps.formInputs.length; j++)
            {
              if(prevProps.formInputs[j].id === this.props.formInputs[i].id)
              {
                oldIdx = j;
                idIsNew = false;
                break;
              }
            }

            // Form input is new to the form
            if(idIsNew)
            {
              //console.log('New ID: ' + this.props.formInputs[i].id);
              stateChanges[this.props.formInputs[i].id] = this.props.formInputs[i].value;
              formInputMap[this.props.formInputs[i].id] = this.props.formInputs[i];
              forceFormInputValuesUpdate = true;
              this.addNewFormInput(this.props.formInputs[i], formErrors);
              formErrorsChanged = true;
            }
            else // Check if form input value modified by parent
            {
              if(prevProps.formInputs[oldIdx].value !== this.props.formInputs[i].value)
              {
                stateChanges[this.props.formInputs[i].id] = this.props.formInputs[i].value;
                formInputMap[this.props.formInputs[i].id] = this.props.formInputs[i];
              }
            }
          }
        }

        // Only update state if any changes actually occurred
        if(Object.keys(stateChanges).length !== 0 || formErrorsChanged)
        {
          //console.log(formErrors);
          //console.log(stateChanges);
          //console.log(formInputMap);
          //console.log('Updating form errors state');
          stateChanges['formErrors'] = formErrors;
          this.setState(stateChanges,
          () =>
          {
            // Form inputs values were modified by parent after they were already instantiated
            // so we must tell them to all update their state
            if(forceFormInputValuesUpdate)
            {
              console.log("\tFormValidated.updateAllFormInputValues()");
              this.updateAllFormInputValues();
            }
            else // Only certain form inputs manually modified
            {
              Object.keys(stateChanges).forEach( (key) =>
              {
                //console.log('State changes');
                //console.log(stateChanges[key]);
                if(stateChanges[key] !== null && key !== 'formErrors')
                {
                  //console.log(stateChanges);
                  this.updateFormInputValue(key,
                                            stateChanges[key],
                                            formInputMap[key].validation,
                                            formInputMap[key].type);
                }
              });
            }
          });
        }
      }
    }
    catch(err)
    {
      console.log(prevProps.formInputs);
      console.log(this.props.formInputs);
      console.log(err);
    }
  }

  /**
    Open the tooltip modal
    @param  {Object}  tooltipData  The tooltip object with message and question
  */
  openTooltip = (tooltipData) =>
  {
    const tooltip = {...this.state.tooltip};
    tooltip.isOpen = true;
    tooltip.message = tooltipData.message;
    tooltip.question = tooltipData.question;
    //console.log(tooltip);
    this.setState({ tooltip: tooltip });
  }

  render()
  {
    console.log('\tForm.render()');//'\n' + JSON.stringify(this.props.formInputs, null, 2));

    let formInputs = null;
    this._formFooter = null;

    // User did not customize grid layout, make everything single row
    if(this.props.columns !== -1 && this.props.formInputs)
    {
      formInputs = this.props.formInputs.map( (input) =>
      {
        if(input.type === '_form_footer_')
        {
          this._formFooter = input;
          return ('');
        }
        return (
          <>
            {input.type === 'link' &&
            <Link
              key={input.id}
              className="btn-link"
              onClick={input.onClick}
              to={input.to}>{input.text}</Link>}

            {input.type !== 'space' &&
            input.type !== 'link' &&
            input.type !== 'point' &&
            <FormInput
              ref={input.type === 'submit' || input.type === 'button' ? this._btns.get(input.id) : this.fieldRefs.get(input.id)}
              data={input}
              key={input.id}
              rowClassName='form-input-row'
              formInputOnChange={this.formInputOnChange}
              errorMessage={this.state.formErrors[input.id]}
              openTooltip={(tooltip) => this.openTooltip(tooltip)}
              value={(this.state[input.id] !== undefined ? this.state[input.id] : '')}
            />}

            {input.type === 'point' &&
            <GoogleMap
              ref={input.type === 'submit' || input.type === 'button' ? this._btns.get(input.id) : this.fieldRefs.get(input.id)}
              data={input}
              key={input.id}
              rowClassName={'form-input-row'}
              formInputOnChange={this.formInputOnChange}
              openTooltip={(tooltip) => this.openTooltip(tooltip)}
              errorMessage={this.state.formErrors[input.id]}
              value={(this.state[input.id] !== undefined ? this.state[input.id] : '')}

              googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />}
          </>
        );
      });
    }
    // User specified custom grid layout
    else if(this.props.columns === -1 && this.props.formInputs )
    {
      let completedRows = [];
      formInputs = this.props.formInputs.map( (input, i) =>
      {
        if(input.type === '_form_footer_')
        {
          this._formFooter = input;
          return ('');
        }
        const row = this.props.formInputs[i].row;
        if(completedRows.indexOf(row) !== -1)
        {
          return ('');
        }

        // Find all columns in this row
        const columnsInRow = [input];
        const upperBound = this.props.formInputs.length - 1;
        let nextRow = i+1;
        while(nextRow <= upperBound && this.props.formInputs[nextRow].row === this.props.formInputs[i].row)
        {
          columnsInRow.push(this.props.formInputs[nextRow]);
          nextRow++;
        }

        completedRows.push(row);

        console.log(columnsInRow);

        return (
          <div className='form-input-row' key={'form-validated-row-' + i}>
            {columnsInRow.map( (formInput, j) =>
            {
              console.log(formInput);
              return (
              <div className={`col-lg-${formInput.col}`} key={'form-validated-column-' + i + '-' + j}>
                {formInput.type === 'link' &&
                <Link
                  key={`form-validated-link-${i}-${j}`}
                  className="btn-link"
                  onClick={formInput.onClick}
                  to={formInput.to}>{formInput.text}</Link>}

                {formInput.type === 'space' && ''}

                {formInput.type !== 'space' &&
                formInput.type !== 'link' &&
                formInput.type !== 'point' &&
                formInput.type !== '_login_container_' &&
                <FormInput
                  ref={formInput.type === 'submit' || formInput.type === 'button' ? this._btns.get(formInput.id) : this.fieldRefs.get(formInput.id)}
                  data={formInput}
                  key={`form-validated-input-${i}-${j}`}
                  rowClassName={''}
                  formInputOnChange={this.formInputOnChange}
                  openTooltip={(tooltip) => this.openTooltip(tooltip)}
                  errorMessage={this.state.formErrors[formInput.id]}
                  value={(this.state[formInput.id] !== undefined ? this.state[formInput.id] : '')}
                />}

                {formInput.type === '_login_container_' &&
                <LoginContainer
                  key={`form-validated-login-container-${i}-${j}`}
                  updateMasterState={(state) => this.setState(state)}
                  showAlert={this.props.showAlert}
                  updateFormInput={(prop, val) =>
                  {
                    // Find form component
                    /*for(let i = 0; i < components.length; i++)
                    {
                      if(components[i].type === 'form')
                      {
                        // Find _login_container_
                        for(let i = 0; i < components[i].form.length; i++)
                        {
                          if(components[i].form[i].type === '_login_container_')
                          {
                            components[i].form[i][prop] = val;
                            updateMasterState('components', components);
                            return;
                          }
                        }
                        break;
                      }
                    }*/
                  }}
                  formInput={formInput}
                  login={() => console.log('Login()')}
                />}

                {formInput.type === 'point' &&
                <GoogleMap
                  ref={formInput.type === 'submit' || formInput.type === 'button' ? this._btns.get(formInput.id) : this.fieldRefs.get(formInput.id)}
                  data={formInput}
                  key={`form-validated-input-${i}-${j}`}
                  rowClassName={''}
                  formInputOnChange={this.formInputOnChange}
                  openTooltip={(tooltip) => this.openTooltip(tooltip)}
                  errorMessage={this.state.formErrors[formInput.id]}
                  value={(this.state[formInput.id] !== undefined ? this.state[formInput.id] : '')}

                  googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />}
              </div>
              );
            })}
          </div>
          );
      });
    }

    return (
    <>
      <this._css
        action="#"
        data-is-form-valid={this.state.isFormValid}
        autoComplete="off"
        className={`form-validated ${(this.props.theme === 'main' ? 'content-container' : '')} `}>

        <TooltipDialog
          tooltip={this.state.tooltip}
          onClose={() =>
          {
            const tooltip = {...this.state.tooltip};
            tooltip.isOpen = false;
            this.setState({ tooltip: tooltip });
          }}
        />

        <div className='formErrors'>
          {this.formErrors}
        </div>

        <div className={`form-group row ${!this.props.description ? 'hidden' : ''}`}>
          <div className="col-lg-12">
            <p>{this.props.description}</p>
          </div>
        </div>

        {formInputs}

  		</this._css>
      {this._formFooter !== null &&
        <this._formFooterCss>
          <FormFooter title={this._formFooter.title} />
        </this._formFooterCss>
      }
    </>
      );
	}

  // Style component
  styleComponent = (siteManager) =>
  {
    var btnColor = 'red';
    if(siteManager !== null && siteManager !== undefined)
    {
      btnColor = siteManager.getColorFor('Form', 'Submit Button');
    }

    let themeMain = '';
    let themeInput = '';
    let themeRow = '';
    if(this.props.theme === 'default' || !this.props.theme)
    {
      themeMain = `flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 100%;`;
      themeInput = `width:100%;
      padding:12px 30px;
      border-color:#DDDDDD;
      border-style: solid;
      border-width: 1px;
      border-radius:4px;
      box-sizing:border-box;
      margin-top:6px;
      margin-bottom:16px;
      resize:vertical;`;
      themeRow = `width: ${(100 - (2 * (this.props.columns ? this.props.columns : 1))) / (this.props.columns ? this.props.columns : 1) + '%'};
      margin-right: 1%;
      margin-left: 1%;
      display: inline-block;`;
    }
    else
    {
      themeMain = '';
      themeInput = `display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding:0.375rem 1.6rem;
      font-size:1rem;
      font-weight:400;
      line-height:1.5;
      color:#495057;
      background-color:#fff;
      background-clip:padding-box;
      border:1px solid #ced4da;
      border-radius: 0.25rem;
      transition:border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;`;
      themeRow = `display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      margin-bottom: 1rem;`;
    }

    // Setup footer css
    this._formFooterCss = StyledComponent.div`
      .form-footer
      {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        background: ${Colors.form.formInputLabel.color};
        padding: 20px 0px;
        font-family: Playfair Display;
        max-width: 420px;
        margin: auto;
      }

      h3
      {
        color: #fff;
        text-align: center;
        font-size: 30px;
      }
    `;

    return StyledComponent.form`
      p
      {
        color:      #2F2734;
        text-align: initial;
      }

      label
      {
        float: left;
        color: ${Colors.form.formInputLabel.color};
      }

      .form-input-row
      {
        ${themeRow}
      }

      /* Input field styling */
      input[type=text], input[type=email],
      input[type=number], input[type=file],
      select, textarea,
      input[type=password]
      {
        ${themeInput}
      }

      input[type=submit], button
      {
        color:          #FFF !important;
        background:     ${btnColor} !important;
        border-color:   ${btnColor} !important;
        padding:        12px 20px;
        border-radius:  4px;
        cursor:         pointer;
        border-width:   0.5px;
        border-style:   solid;
      }

      select /* Chrome likes to think you don't really want to apply padding to select elements */
      {
        -webkit-appearance: none;
        -moz-appearance:    none;
        appearance:         none;
      }

      /* Drop down indicator */
      .dropdown:before
      {
        content: "";
        position: absolute;
        right: 10px;
        top: 55%;
      	width: 0;
      	height: 0;
      	border-left: 10px solid transparent;
      	border-right: 10px solid transparent;
      	border-top: 10px solid #000;
      }

      .tooltip-question
      {
        font-weight: 500 !important;
        color: #007bff !important;
        float: right;
        cursor: pointer;
      }

      ${themeMain}
     `;
  }
}
