import React from 'react';
import { withRouter } from 'react-router'
import { Nav } from 'react-bootstrap';
import StyledComponent from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import { ApiManager } from '../../managers';

/**
  * Navigation list
  *
  * @param 	{String}	default 	The default page to treat as home link
  *
  *	State:
  */
class NavList extends React.Component
{
  // MARK: - Data fields
  _css = null;


  // MARK: - Constructor
  constructor(props)
  {
    console.log("\tNavList()");
    super(props);
    this.state =
    {
      component:
      {
        navData: [],
        dynamicData: true
      }
    };
    this._css = this.styleComponent(props.siteManager);
  }

  async componentDidMount()
  {
    console.log('\tPosts.componentDidMount()');
    this._isMounted = true;

    // Fetch data
    this.props.updateMasterState({ isLoading: true });
    const response = await ApiManager.SendRequest(
    {
      route: 'component',
      action: 'side-nav'
    });
    if(response.error !== null)
    {
      this.props.updateMasterState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', response.error, 'danger');
      return;
    }

    console.log(response.results);

    // Update parent
    const component = {...this.state.component};
    if(this.state.component.dynamicData)
    {
      component.navData = response.results;
    }

    this.setState({ component: component });
    this.props.updateMasterState({ isLoading: false });
  }


  // MARK: - Onchange handlers
  navOnChange = (link) =>
  {
    if(this.props.active != link)
    {
      // If we are on the admin page then just pass this prop down to the data manager
      // no need to update URL
      if(this.props.user &&
        (this.props.user.authorization.type !== 'admin' && this.props.user.authorization.type !== 'customer'))
      {
        this.props.history.push('/' + link.toLowerCase());
      }

      if(this.props.location.pathname !== 'home')
      {
        this.props.updateMasterState({ selectedNavItem: link }, () => this.props.history.push('/home'));
      }
      else
      {
        this.props.updateMasterState({ selectedNavItem: link });
      }
    }
  }


	// MARK: - Render
  reload = () =>
  {
    this.forceUpdate();
  }

  shouldComponentUpdate(nextProps, nextState)
  {
    // If style manager provided now, update css
    if(nextProps.siteManager !== null && this.props.siteManager === null)
    {
      this._css = this.styleComponent(nextProps.siteManager);
    }

    // If manually clearing active nav item call prop onChange
    return (
      this.props.active !== nextProps.active ||
      this.props.user._id.toString() !== nextProps.user._id.toString() ||
      this.props.user.photo !== nextProps.user.photo ||
      this.props.siteManager !== nextProps.siteManager ||
      this.state.component.navData !== nextState.component.navData
    );
  }

  render()
  {
    console.log('\tNavList.render()');
    console.log(this.state.component);
    let name = '';
    if(this.props.user)
    {
      name = (this.props.user.name ? this.props.user.name : (this.props.user.firstName + ' ' + this.props.user.lastName));
    }

    return (
    <this._css>
      <Paper elevation={3} variant='outlined'>
        <Avatar
          alt="Profile image"
          src={this.props.user.photo ? this.props.user.photo : require('../../asset/no-photo.png')}
          className={'avatar'}
        />
        {name.length > 1 &&
        <p
          className='name'
        >{name}</p>}
        <Nav
          activeKey={`${this.props.active}`}
          className="navlist-container"
          onSelect={e => this.navOnChange(e)}>
            {this.state.component.navData && this.state.component.navData.map( (item, i) =>
              <Nav.Link
                key={i}
                eventKey={item.title}
              >
                {item.icon.indexOf('.png') === -1 ? <span className={item.icon}/>
                                                  : <img className={'link-img'} src={require('../../asset/' + item.icon)} />}
                {item.displayName}
              </Nav.Link>
            )}
        </Nav>
      </Paper>
    </this._css>);
  }


  styleComponent = (siteManager) =>
  {
    var linkText = '#007BF9';
    var linkTextSelected = '#FFFFFF';
    var bgColor = '#FFF';

    if(siteManager !== null)
    {
      linkText = siteManager.getColorFor('Side Navigation', 'Link Text');
      linkTextSelected = siteManager.getColorFor('Side Navigation', 'Link Text (Selected)');
      bgColor = siteManager.getColorFor('Side Navigation', 'Background');
    }

    return StyledComponent.div`
      a, .navbar-brand, .navbar-nav .nav-link
      {
        &:hover
        {
        color: black;
        }

      	span
      	{
      	 	padding-right: 5px;
      	}

      	inline-size: fit-content;
      	padding-right: 18px;
        color: ${linkText};
      }
      .nav-link
      {
        text-align: center;
        padding: 0.2rem 0.5rem;
        align-self: center;
      }
      .link-img
      {
        width: 30px;
        margin-right: 10px;
      }

      .active
      {
    	  color: ${linkTextSelected};
    	  background: #000;
    	  border-radius: 10px;

    	  &:hover
    	  {
    		  color: #FFF;
    	  }
      }

      .name
      {
        text-align: center;
        color: ${linkText};
        font-weight: 600;
      }

      .avatar
      {

        width: 120px;
        height: 120px;
        margin: auto;
        margin-top: 5px;
        border: 3px solid #cecfd1;
      }

      .navlist-container
      {
        flex-direction: column!important;
        @media only screen and (max-width: 992px)
        {
          flex-direction: row!important;
          justify-content: center;
        }
      }

      background: ${bgColor};
      height: 100%;
      position: fixed;
      z-index: 0;
      /*top: 0;*/
      left: 0;
      overflow-x: hidden;
      display: inline-block;

      @media only screen and (max-width: 992px)
      {
        width: 100%
        height: fit-content;
        position: unset;
      }
      @media only screen and (min-width: 993px)
      {
        width: 100%
        height: fit-content;
        position: unset;
      }
    `;
  }
}

export default withRouter(NavList);
