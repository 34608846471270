import React from 'react';
import { withRouter } from 'react-router'
import { Nav, Navbar } from 'react-bootstrap';
import StyledComponent from 'styled-components';
import { ApiManager } from '../../managers';

class NavigationBar extends React.Component
{
  // MARK: - Data fields
  _css = null;


  // MARK: - Constructor
  constructor(props)
  {
    console.log("\tNavigationBar()");
    super(props);
    this.state =
    {
      component:
      {
        navData: [],
        dynamicData: true
      },
    }

    this._css = this.styleComponent(props.siteManager);
  }

  async componentDidMount()
  {
    console.log('\tNavigationBar.componentDidMount()');
    this._isMounted = true;

    if(this.props.cookies.get('token'))
    {
      await this.loadData();
    }
  }

  loadData = async() =>
  {
    // Fetch data
    this.props.updateMasterState({ isLoading: true });
    const response = await ApiManager.SendRequest(
    {
      route: 'component',
      action: 'side-nav'
    });
    if(response.error !== null)
    {
      this.props.updateMasterState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', response.error, 'danger');
      return;
    }

    // Update parent
    const component = {...this.state.component};
    if(this.state.component.dynamicData)
    {
      component.navData = response.results;
    }

    this.setState({ component: component });
    this.props.updateMasterState({ isLoading: false });
  }


  // MARK: - Onchange handlers
  navOnChange = (selectedNavItem) =>
  {
    console.log(selectedNavItem);

    if(this.props.isMobile &&
      this.props.selectedNavItem !== selectedNavItem &&
      selectedNavItem !== '/logout' &&
      this.props.location.pathname !== 'home')
    {
      this.props.updateMasterState({ selectedNavItem: selectedNavItem }, () =>
      {
        this.props.history.push('/home');
      });
    }

    if(this.props.selectedNavItem !== selectedNavItem &&
      selectedNavItem !== '/logout')
    {
      this.props.history.push('/' + selectedNavItem.toLowerCase().replace('/', ''));
      this.props.updateMasterState({ selectedNavItem: selectedNavItem })
    }
  }

  // MARK: - Render
  reload = () =>
  {
    this.forceUpdate();
  }

  render()
  {
    const isLoggedIn = this.props.cookies.get('token');

    console.log('NavigationBar.render()');
    return (
    <this._css>
      <Navbar expand="lg" variant="dark">
        <Navbar.Brand
          onClick={(e) =>
          {
            this.props.updateMasterState({ selectedNavItem: -1 });
            this.props.history.push(isLoggedIn ? '/home' : '/');
          }}
        >{this.props.title}</Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="ml-auto"
            onSelect={e => this.navOnChange(e)}
          >

            {this.props.isMobile &&
            this.state.component &&
            this.state.component.navData.map( (item, i) =>
            <Nav.Item key={`navigation-bar-${i}-item`}>
              <Nav.Link
                key={`navigation-bar-${i}-link`}
                eventKey={item.title}
              >
                {item.icon.indexOf('.png') === -1 ? <span className={item.icon}/>
                                                  : <img className={'link-img'} src={require('../../asset/' + item.icon)} />}
                {item.displayName}
              </Nav.Link>
            </Nav.Item>)}
            <Nav.Item
              key={'navigation-bar-logout-item'}
            >
              {this.props.loggedIn ? <Nav.Link key={`navigation-bar-logout-link`} href="/logout">Logout</Nav.Link> :
                                  (this.props.path === 'login' ? '' : <Nav.Link key={`navigation-bar-logout-link`} href="/login">Login</Nav.Link>)}
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </this._css >);
  }

  styleComponent = (siteManager) =>
  {
    var bgColor = '#e19044';
    var textColor = '#FFF';

    return StyledComponent.div`
      .link-img
      {
        width: 30px;
        margin-right: 10px;
      }
      .name
      {
        text-align: center;
        color: ${textColor};
        font-weight: 600;
      }
      .avatar
      {
        width: 120px;
        height: 120px;
        margin: auto;
        margin-top: 5px;
        border: 3px solid #cecfd1;
      }
      .navbar
      {
        background-color: ${bgColor};
      }
      a, .navbar-brand, .navbar-nav .nav-link
      {
        color: ${textColor} !important;

        &:hover
        {
          color: white;
        }
      }
    `;
  }
}

export default withRouter(NavigationBar);
