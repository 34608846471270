export default
{
  navigation:
  {
    notifications:
    {
      header:
      {
        title: 'Notifications',
        clearAll: 'Mark all as read'
      },
      body:
      {
        none: 'No notifications'
      },
      item:
      {
        clear: 'Mark as read'
      }
    }
  },
  register:
  {
    popupDialog:
    {
      okayBtn: 'Okay!'
    },
  }
};
